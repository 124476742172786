import React, {Component} from 'react';
import { Image, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Grid, Card, CardActionArea, CardActions, CardContent, CardMedia, 
    Typography, Button, Checkbox, Radio, withStyles } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
// import { withStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
// import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
// import Checkbox from '@material-ui/core/Checkbox';
// import { Radio } from '@material-ui/core';
// import CheckIcon from '@material-ui/icons/Check';
import Preloader from '../../components/Preloader';
import '../../App.css';

import fondoCard from '../../assets/fondo-card.png';
import timer from '../../assets/timer.png';
import const_values from '../../utils/ConstValues';
import intructionTest from '../../utils/IntructionTest';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
// import { platform } from 'chart.js';

// const styles = {
//     root: {
//       flexGrow: 1,
//       marginTop: 20
//     },
//     card: {
//         maxWidth: 345,
//         margin: 10
//     },
//     media: {
//         height: 140,
//     },
//     textCompany: {
//         marginLeft: 5,
//         color: '#3a4f9e',
//         fontWeight: '500'
//     },
//     checkRadio: {
//         color: "#13c552",
//         '&$checked': {
//           color: "#13c552",
//         },
//     },
// };

// const CustomColorCheckbox = withStyles({
//     root: {
//       color: "#13c552",
//       "&$checked": {
//         color: "#13c552"
//       }
//     },
//     checked: {}
// })((props) => <Checkbox color="default" {...props} />);

// const CustomColorRadio = withStyles({
//     root: {
//       color: "#848484",
//       "&$checked": {
//         color: "#13c552"
//       }
//     },
//     checked: {}
// })((props) => <Radio color="default" {...props} />);

class ListEvaluation extends Component{

    constructor(){
        super();
        this.state={
            evaluationsCandidate: [],
            isShowModal: false,
            evaluationSelect: '',
            evaluationIqOk: false,
            instructionEvaluation: '',
            loader: false
        }
        this.closeModal = this.closeModal.bind(this);
        this.startIqEvaluation = this.startIqEvaluation.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    componentDidMount(){
        let candidate = this.props.params.candidate;
        this.getEvaluationCandidate(candidate);
    }

    getEvaluationCandidate(candidate){
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/evaluations_candidate/'+candidate)
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                data.text.forEach(element => {
                    if(element.test_iq == '1'){
                        this.getFactors(element)
                    }
                    if(element.test_in == '1'){
                        element.titleTest = 'Test de honestidad';
                        element.textTest = 'Es muy sencillo! Se honesto y responde las preguntas con transparencia. Cualquier intento de manipulación podría afectar tu aplicación en el proceso.';
                        element.textTime = '15 - 20 minutos';
                        element.evaluation_iq = '0';
                        this.getTotalEvaluationIntegrity(element);
                    }
                });
            }else{
                this.logout();
            }
        })
        .catch(console.error)
    }

    getFactors(evaluation){
        fetch(const_values.SERVICE_CONNECT+'factores/byTypeEvaluation/3')
        .then(res => res.json())
        .then((data) => {
            data.forEach(element => {
                let titleIntelligence = this.convertTitleIntelligence(element.nombre);
                let factorName = this.replaceTilde(element.nombre);
                console.log(element);
                let item = {
                    apellido: evaluation.apellido,
                    candidatos_identificacion: evaluation.candidatos_identificacion,
                    empresas_ruc: evaluation.empresas_ruc,
                    estado: evaluation.estado,
                    evaluacion_poligrafo: evaluation.evaluacion_poligrafo,
                    evaluaciones_codigo: evaluation.evaluaciones_codigo,
                    factorId: element.id,
                    fecha_evaluacion: evaluation.fecha_evaluacion,
                    fecha_limite: evaluation.fecha_limite,
                    id: evaluation.id,
                    nombre_candidato: evaluation.nombre_candidato,
                    razon_social: evaluation.razon_social,
                    status_camara: evaluation.status_camara,
                    tipos_evaluaciones_id: evaluation.tipos_evaluaciones_id,
                    evaluation_iq: '1',
                    textTest: "Queremos validar tu compatibilidad con el puesto. Intenta responder la mayoría de preguntas en el menor tiempo posible. ¡Éxitos!",
                    textTime: `${element.tiempo} minutos`,
                    titleTest: 'Test de aptitudes '+titleIntelligence,
                    identifyFactor: factorName
                }
                this.getEvaluationIQ(item, element.id);
            });
        })
        .catch(console.error)
    }

    getTotalEvaluationIntegrity(evaluation){
        let evaluationID = evaluation.id.toString();
        firebase.firestore().collection('totals').doc(evaluationID).get().then(document =>{
            evaluation.status = 'Comenzar';
            evaluation.css = 'disabled-mask-inactive';
            if(document.data() != undefined){
                evaluation.status = 'Finalizado';
                evaluation.css = 'disabled-mask-active';
            }
            this.state.evaluationsCandidate.push(evaluation);
            this.setState({
                evaluationsCandidate: this.state.evaluationsCandidate,
                loader: true
            });

        })
    }

    getEvaluationIQ(evaluation, factorId){
        const resultDocument = firebase.firestore()
            .collection('resultados_candidato')
            .doc(evaluation.id);

        resultDocument.collection('resultados')
        .doc(factorId).get()
        .then(document =>{
            evaluation.status = 'Comenzar';
            evaluation.css = 'disabled-mask-inactive';
            if(document.data() != undefined){
                evaluation.status = 'Finalizado';
                evaluation.css = 'disabled-mask-active';
            }
            this.state.evaluationsCandidate.push(evaluation);
            this.setState({
                evaluationsCandidate: this.state.evaluationsCandidate,
            });
        })
    }

    convertTitleIntelligence(title){
        let lowerTitle = title.toLowerCase();
        let lastIndexCharter = lowerTitle.length - 1; 
        let lastCharter = lowerTitle.charAt(lastIndexCharter);
        const vocales = ["a", "e", "i", "o", "u"];
        if (vocales.indexOf(lastCharter) === -1) {
            lowerTitle = lowerTitle+'es';
        } else {
            lowerTitle = lowerTitle+'s';
        }
        return lowerTitle;
    }

    replaceTilde(title){
        const tildes = ["á", "é", "í", "ó", "ú"];
        const vocales = ["a", "e", "i", "o", "u"];
        let lowerTitle = title.toLowerCase();
        tildes.forEach((tilde, index) => {
            lowerTitle = lowerTitle.replace(tilde, vocales[index]);
        })
        return lowerTitle;
    }

    goEvaluation = (evaluation) => () => {
        if(evaluation.evaluation_iq === "1"){
            this.setState({ 
                isShowModal: true,
                evaluationSelect: evaluation,
                instructionEvaluation: intructionTest[evaluation.identifyFactor]
            });
        }else{
            this.props.navigate('/evaluation/'+evaluation.id);
        }
    }

    logout() {
        if(firebase.auth().currentUser.email === null){
            origin = '';
            firebase.auth().currentUser.delete().then(function () {
                console.log('delete successful?')
            }).catch(function (error) {
                console.error({error})
            })
        }
        firebase.auth().signOut()
        .then(() => {
            this.props.navigate('/');
        })
        .catch(error => {
            console.error(error);
            this.props.navigate('/');
        })
	}

    salir = () => () => {
        this.logout();
        // window.open('https://www.integritas.ec/', '_self');
    }

    closeModal() {
        this.setState({ 
            isShowModal: false,
            evaluationSelect: '' 
        });
    }

    handleCheckboxChange(){
        this.setState({evaluationIqOk: !this.state.evaluationIqOk});
    }

    startIqEvaluation(){
        let evaluationSelect = Object.assign(this.state.evaluationSelect);
        this.setState({ 
            isShowModal: false,
            evaluationSelect: '' 
        });
        this.changeStatusEvaluation(evaluationSelect, '2');
    }

    changeStatusEvaluation(evaluationSelect, status){
        let data = {
            evaluations_id: evaluationSelect.id,
            status: status
        };
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/update_status_evaluation', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                this.props.navigate('/evaluation_iq/'+evaluationSelect.id+'/'+evaluationSelect.factorId);
            }else{
                toast.error(data.text);
            }
        })
        .catch(err =>{
            toast.error(err);
            this.setState({loading: false, buttonDisabled: false});
        })
    }

    render(){
        // const { classes } = this.props;
        let borderType = 'border-question-lg';
        let paddingModal = '20px 40px';
        let marginHeader = 150;
        if(window.screen.width <= 480){
            marginHeader = 10;
            paddingModal = '10px 10px';
            borderType = 'border-question-xs';
        }
        if(!this.state.loader){
            return (<Preloader/>)
        }
        return(
            <Grid container style={{flexGrow: 1, marginTop: 20}} spacing={2}>
                <Grid item xs={12}>
                    <div style={{textAlign: 'left', width: '100%', marginBottom: 20, marginLeft: marginHeader}}>
                        <h3>Bienvenido, {this.state.evaluationsCandidate.length > 0 &&
                                this.state.evaluationsCandidate[0].nombre_candidato.split(' ')[0]
                            }!
                        </h3>
                        <p>Te mostramos a continuación los test que tienes pendientes por rendir.</p>
                    </div>
                    <Grid container spacing={2} style={{display:'flex', justifyContent: 'center'}}>
                        {this.state.evaluationsCandidate.map((candidate, index) => (
                            <Card key={'C'+index} style={{maxWidth: 345, margin: 10}}>
                                <CardActionArea disabled={true} style={{position: 'relative'}}>
                                    <div className={candidate.css}></div>
                                    {/* <CardMedia
                                        className={classes.media}
                                        image={fondoCard}
                                        title="Contemplative Reptile"
                                    /> */}
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="h3">
                                            {candidate.titleTest}
                                        </Typography>
                                        <div style={{height: 80}}>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                {candidate.textTest}
                                            </Typography>
                                        </div>
                                        <div style={{marginLeft: 5,color: '#3a4f9e',fontWeight: '500'}}>
                                            {candidate.razon_social}
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions style={{borderTop: 'solid 1px #BCB9B9'}}>
                                    <Grid container item xs={12}>
                                        <Grid item xs={6}>
                                            <Image src={timer} 
                                                style={{width: 26}}
                                            />
                                            <span style={{marginLeft: 5,color: '#3a4f9e',fontWeight: '500'}}>{candidate.textTime}</span>
                                        </Grid>
                                        <Grid container item xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                            {candidate.status == 'Finalizado' ? 
                                            ( 
                                                <Button startIcon={<CheckIcon />} size="small" 
                                                    style={{textTransform: 'capitalize',color: '#04B404', fontSize: 15}} 
                                                    disabled={true}
                                                >
                                                    Finalizado
                                                </Button>
                                            )
                                            :
                                            (
                                                <Button size="small"
                                                    style={{textTransform: 'capitalize', fontSize: 15}} 
                                                    onClick={this.goEvaluation(candidate)}
                                                >
                                                    Comenzar
                                                </Button>
                                            )
                                            }
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        ))}
                    </Grid>
                    <div style={{marginTop: 20, marginBottom: 20, textAlign: 'center'}}>
                        <Button color="secondary" onClick={this.salir()}>
                            Salir
                        </Button>
                    </div>
                </Grid>
                <Modal  size="lg" centered show={this.state.isShowModal} onHide={this.closeModal}>
                    <div style={{padding: paddingModal}}>
                        <Modal.Header closeButton style={{color: '#04B404', fontSize: 30}}>
                            {this.state.evaluationSelect.titleTest}
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{margin: 10, color: '#585858'}}>
                                <p>Lea las instrucciones detenidamente.</p>
                                <p>A continuación, se mostrará un ejemplo de pregunta de este test para tu familiarización.</p>
                                <p>{this.state.instructionEvaluation.instruction}</p>
                                <p>{this.state.instructionEvaluation.note}</p>
                                {this.state.instructionEvaluation != '' ? 
                                (
                                    <Grid container item xs={12} 
                                        style={{border: 'solid 1px #A4A4A4', borderRadius: 15, marginBottom: 10}}
                                    >
                                        <Grid item md={this.state.instructionEvaluation.widthQuestion} xs={12} className={borderType}>
                                            {this.state.instructionEvaluation.question.imagen == '' ? 
                                            (<span style={{fontSize: 13}}>{this.state.instructionEvaluation.question.pregunta}</span>)
                                            :
                                            (<Image src={const_values.IMAGE_CONNECT+'instruction/questions/'+this.state.instructionEvaluation.question.imagen} 
                                                style={{width: this.state.instructionEvaluation.question.width}}
                                            />)
                                            }
                                            
                                        </Grid>
                                        {this.state.instructionEvaluation.answers.map((answer, indexA) => (
                                            <Grid key={'A'+indexA} 
                                                md={Math.floor(10/this.state.instructionEvaluation.answers.length) > 3 ? 3: Math.floor(10/this.state.instructionEvaluation.answers.length)}
                                                xs={12}
                                                item 
                                                style={{marginRight: 10, padding: 10, textAlign: 'center', fontSize: 12}}
                                            >
                                                <Radio
                                                    checked={answer.checked}
                                                    value={answer.respuesta}
                                                    name="radio-button-demo"
                                                    inputProps={{'aria-label': answer.respuesta}}
                                                    readOnly={true}
                                                    sx={{
                                                        color: '#848484',
                                                        '&.Mui-checked': {
                                                            color: '#13c552',
                                                        },
                                                    }}
                                                />
                                                {/* <CustomColorRadio
                                                    checked={answer.checked}
                                                    value={answer.respuesta}
                                                    name="radio-button-demo"
                                                    aria-label="A"
                                                    readOnly={true}
                                                /> */}
                                                {answer.imagen == '' ? 
                                                (<span>{answer.respuesta}</span>)
                                                :
                                                (<Image src={const_values.IMAGE_CONNECT+'instruction/answers/'+answer.imagen} 
                                                    style={{width: answer.width}}
                                                />)
                                                }
                                            </Grid>
                                        ))}
                                    </Grid>
                                )
                                :
                                (<div></div>)
                                }
                                <p>Recuerda que tienes tiempo limitado, te recomendamos responder la mayor cantidad de
                                preguntas dentro del tiempo establecido y lo más pronto posible.</p>
                                <p>Para comenzar, presiona el botón inferior aceptando que entendiste las instrucciones para 
                                rendir el test.</p>
                                
                                <Checkbox 
                                    checked={this.state.evaluationIqOk} 
                                    onChange={this.handleCheckboxChange}
                                    sx={{
                                        color: '#13c552',
                                        '&.Mui-checked': {
                                            color: '#13c552',
                                        },
                                    }}
                                />
                                {/* <CustomColorCheckbox checked={this.state.evaluationIqOk} onChange={this.handleCheckboxChange}/> */}
                                Acepto haber entendido las instrucciones del test.
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="contained" color="primary" onClick={this.startIqEvaluation} disabled={!this.state.evaluationIqOk}>
                            Comenzar test
                        </Button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </Grid>
        )
    }
}

// ListEvaluation.propTypes = {
//     classes: PropTypes.object.isRequired
// }

export default ListEvaluation;