import React, {Component} from 'react';
import { Image, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Grid, Radio, Button } from '@mui/material';
import { blue } from '@mui/material/colors';

// import { withStyles } from '@material-ui/core/styles';
// import { blue } from '@material-ui/core/colors';
// import Grid from '@material-ui/core/Grid';
// import { Radio } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
// import { css } from "@emotion/core";

import '../../App.css';

import clock from '../../assets/timer.png';

import Preloader from '../../components/Preloader';
import ProgressQuest from '../../components/ProgressQuest';
import const_values from '../../utils/ConstValues';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// const styles = theme => ({
//     root: {
//       width: '100%',
//     },
//     barColorPrimary: {
//         backgroundColor: blue[500]
//     },
//     colorPrimary: {
//         backgroundColor: blue[100]
//     }
// });

// const CustomColorRadio = withStyles({
//     root: {
//       color: "#848484",
//       "&$checked": {
//         color: "#13c552"
//       }
//     },
//     checked: {}
// })((props) => <Radio color="default" {...props} />);

class EvaluationIQ extends Component{

    _isMounted = false;

    constructor(){
        super();
        this.state={
            loadingInit: false,
            evaluation: '',
            titleTest: '',
            minutes: 5,
            seconds: 60,
            answers_select: [],
            questions: [],
            number_questions: 0,
            widthQuestion: 3,
            isShowModal: false,
            factorsIq: '',
            isTotalIntegrity: false,
            initTimer: false,
            evaluation_time: 0
        }

        this.timer = false;
        this.miliseconds = 0;
        this.countFactors = 0;
        this.closeModal = this.closeModal.bind(this);
        this.handleLeavePage = this.handleLeavePage.bind(this);
    }

    componentDidMount(){
        window.location.hash="no-back-button";
        window.location.hash="Again-No-back-button";//esta linea es necesaria para chrome
        window.onhashchange=function(){window.location.hash="no-back-button";}

        this.callWindowMouseout();
        window.addEventListener('beforeunload', this.handleLeavePage);
        this._isMounted = true;
        let evaluation_id = this.props.params.evaluation_id;
        this.setState({loadingInit: false});
        this.getEvaluation(evaluation_id);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.timer);
        window.removeEventListener('beforeunload', this.handleLeavePage);
    }

    windowMouseout( obj, evt, fn ) {
        if ( obj.addEventListener ) {
            obj.addEventListener( evt, fn, false );
        }
        else if ( obj.attachEvent ) {
            obj.attachEvent( 'on' + evt, fn );
        }
    }

    callWindowMouseout(){
        this.windowMouseout( document, 'mouseout', event => {
            event = event ? event : window.event;
            var from = event.relatedTarget || event.toElement;
            if (!from || from.nodeName === 'HTML') {
                this.updateOutputEvaluation();
            }
        } );
    }

    updateOutputEvaluation(){
        let data = {
            evaluation_id: this.props.params.evaluation_id,
        };
        fetch(const_values.SERVICE_CONNECT+'pruebas/save_unload_evaluation', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then((data) => {
            console.log('aqui');
        })
        .catch(console.error)
    }

    handleLeavePage(e) {
        const confirmationMessage = 'Salir';
        e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
        return confirmationMessage;              // Gecko, WebKit, Chrome <34
    }

    getEvaluation(evaluation_id){
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/get_evaluation/'+evaluation_id)
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                this.getFactors(data.text);
            }else{
                toast.error('Los datos de evaluación no se han cargado correctamente');
            }
        })
        .catch(console.error)
    }

    getFactors(evaluation){
        let factor_id = this.props.params.factor_id;
        fetch(const_values.SERVICE_CONNECT+'factores/byFactorId/'+factor_id)
        .then(res => res.json())
        .then((data) => {
            if(data.status == 1){
                let widthQuestion = 2;
                if(data.text.nombre == 'Abstracta'){
                    widthQuestion = 4;
                }
                if(data.text.nombre == 'Lógica'){
                    widthQuestion = 3;
                }
                let titleIntelligence = this.convertTitleIntelligence(data.text.nombre);
                this.setState({
                    evaluation: evaluation,
                    titleTest: 'Test de aptitudes '+titleIntelligence,
                    widthQuestion: widthQuestion,
                    evaluation_time: data.text.tiempo,
                    minutes: data.text.tiempo,
                });
            }
            this.getQuestions();
        })
        .catch(console.error)
    }

    convertTitleIntelligence(title){
        let lowerTitle = title.toLowerCase();
        let lastIndexCharter = lowerTitle.length - 1; 
        let lastCharter = lowerTitle.charAt(lastIndexCharter);
        const vocales = ["a", "e", "i", "o", "u"];
        if (vocales.indexOf(lastCharter) === -1) {
            lowerTitle = lowerTitle+'es';
        } else {
            lowerTitle = lowerTitle+'s';
        }
        return lowerTitle;
    }

    getQuestions(){
        let evaluation_id = this.props.params.evaluation_id;
        let factor_id = this.props.params.factor_id;
        fetch(const_values.SERVICE_CONNECT+'pruebas/questions_iq/'+evaluation_id+'/'+factor_id)
        .then(res => res.json())
        .then((data) => {
            if(data.status === undefined){
                if(data.length > 0){
                    let answersList = {};
                    data.forEach(element => {
                        answersList[element.id] = {
                            evaluacion_candidatos_id: this.props.params.evaluation_id,
                            preguntas_id: element.id,
                            factores_id: element.factores_id,
                            tipo_factor: element.tipo_factor,
                            respuesta: '',
                            calificacion: 0,
                            respuestas_id: '',
                            tiempo: '',
                            captura: ''
                        }
                    });
                    this.setState({loadingInit: true});
                    this.setState({
                        questions: data,
                        number_questions: data.length,
                        answers_select: answersList
                    });
                    this.getFactorsIQ();
                }else{
                    toast.error('No se pudieron recuperar las preguntas para su evaluación, reportelo a traves de los canales de comunicación de integritas.ec');
                    this.props.navigate('/list_evaluations/'+this.state.evaluation.candidatos_identificacion);
                }
            }else{
                toast.error(data.text);
                this.messageErrorData('preguntas');
            }
        })
        .catch(console.error)
    }

    getFactorsIQ(){
        fetch(const_values.SERVICE_CONNECT+'factores/byTypeEvaluation/3')
        .then(res => res.json())
        .then((data) => {
            if(data.status === undefined){
                this.setState({factorsIq: data});
                this.getTotals();
            }else{
                this.messageErrorData(data.text);
            }
        })
        .catch(console.error)
    }

    getTotals(){
        firebase.firestore()
        .collection('totals')
        .doc(this.props.params.evaluation_id)
        .get()
        .then(snapshot => {
            this.setState({isTotalIntegrity: false})
            if(snapshot.data()){
                this.setState({isTotalIntegrity: true})
            }
            
            this.timer = true;
            // this.initTimer = true;
            this.setState({initTimer: true})
            // console.log('inicio', this.state.initTimer);
            this.validFactoresIQ();
            this.startCounter();
        });
    }

    messageErrorData(info){
        toast.error(
            `No se han podido recuperar las ${info}, necesario para rendir la evaluación, por favor informar a la empresa`,
            // {onClose: this.salir}
        );
    }

    startCounter(){
        if(this.timer){
            // this.validFactoresIQ();
            this.timer = setInterval(() => {
                this.miliseconds += 1000; 
                if(this.state.seconds == 0){
                    if(this.state.minutes == 0){
                        this.state.minutes = 0;
                        this.finalTime();
                    }else{
                        this.state.minutes--;
                        if(this._isMounted){
                            this.setState({minutes: this.state.minutes})
                        }
                        this.state.seconds = 59;
                    }
                }
                if(this._isMounted){
                    this.setState({seconds: this.state.seconds})
                }
                this.state.seconds--;
                if(this.state.minutes == this.state.evaluation_time){
                    this.state.minutes = this.state.evaluation_time - 1;
                }
            }
            ,1000);
        }
    }

    finalTime(){
        clearInterval(this.timer);
        this.timer = false;
        this.saveEvaluation();
    }

    handlerOptions = (question, answer) => () =>{
        this.state.answers_select[question.id].respuesta = answer.respuesta;
        this.state.answers_select[question.id].calificacion = answer.calificacion;
        this.state.answers_select[question.id].respuestas_id = answer.codigo;
        this.setState({answers_select: this.state.answers_select});
    }

    save = (withoutModal) => () => {
        clearInterval(this.timer);
        this.timer = false;
        if(this.validateAswerEmpty() || withoutModal){
            clearInterval(this.timer);
            this.timer = false;
            this.saveEvaluation();
        }else{
            this.setState({isShowModal: true});
        }
    }

    saveEvaluation(){
        let questionsIds = Object.keys(this.state.answers_select);
        let numQuestions = questionsIds.length - 1;
        let puntaje = 0;
        questionsIds.forEach((questionId, index) => {
            let answer = this.state.answers_select[questionId];
            if(answer.respuesta == ''){
                answer.respuesta = 'sin respuesta';
                answer.calificacion = 0;
                answer.respuestas_id = '';
            }
            let qualification = parseFloat(answer.calificacion);
            puntaje += qualification;
            console.log(answer, numQuestions, index, puntaje);
            this.saveResultEvaluation(answer, numQuestions, index, puntaje);
        });
    }

    validateAswerEmpty(){
        let questionsIds = Object.keys(this.state.answers_select);
        let countAnswersEmpty = 0;
        questionsIds.forEach((questionId, index) => {
            let answer = this.state.answers_select[questionId];
            if(answer.respuesta == ''){
                countAnswersEmpty++;
            }
        })
        let result = true;
        if(countAnswersEmpty > 0){
            result = false;
        }
        return result;
    }

    saveResultEvaluation(data_save, numQuestions, indexQ, puntaje){
        if(data_save){
            const resultDocument = firebase.firestore()
                .collection('resultados_evaluacion')
                .doc(this.props.params.evaluation_id);
    
            resultDocument.collection('resultados')
            .doc(data_save.preguntas_id)
            .set(data_save)
            .then((docRef) => {
                if(indexQ == numQuestions){
                    let totalQuestions = numQuestions + 1;
                    let porcentaje = Math.round((puntaje * 100)/totalQuestions, 2);
                    let dataResultCandidate = {
                        candidatos_identificacion: this.state.evaluation.candidatos_identificacion,
                        empresas_ruc: this.state.evaluation.empresas_ruc,
                        factores_id: data_save.factores_id,
                        porcentaje: porcentaje,
                        puntaje: puntaje,
                        tiempo: this.miliseconds,
                        tipo_factor: data_save.tipo_factor,
                        total: totalQuestions
                    }
                    this.saveResultCandidate(dataResultCandidate);
                }
            })
            .catch(err => {
                console.error(err);
            })
        }
    }

    saveResultCandidate(data_save){
        const resultDocument = firebase.firestore()
            .collection('resultados_candidato')
            .doc(this.props.params.evaluation_id);

        resultDocument.collection('resultados')
        .doc(data_save.factores_id)
        .set(data_save)
        .then((docRef) => {
            this.validResultFactor(data_save.factores_id, data_save);
        })
        .catch(err => {
            console.error(err);
        })
    }

    validResultFactor(factor_id, data_save){
        let evaluation_id = this.props.params.evaluation_id;
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/valid_result_factor/'+evaluation_id+'/'+factor_id)
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                this.saveResultCandidateMysql(factor_id, data_save);
            }else{
                let valueEvaluationIn = this.state.isTotalIntegrity;
                if(this.state.evaluation.test_in != '1'){
                    valueEvaluationIn = true;
                }
                let factorsIQSize = this.state.factorsIq.length - 1;
                if(this.countFactors == factorsIQSize && valueEvaluationIn){
                    this.saveStatusEvaluationMysql('3');
                }else{
                    toast.info('Prueba finalizada correctamente');
                    this.props.navigate('/list_evaluations/'+this.state.evaluation.candidatos_identificacion);
                }
            }
        })
        .catch(console.error)
    }

    saveResultCandidateMysql(factor_id, data_save){
        let data = {
            evaluation_id: this.props.params.evaluation_id,
            factor_id: factor_id,
            data_save: data_save
        }
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/update_result_candidate', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            let valueEvaluationIn = this.state.isTotalIntegrity;
            if(this.state.evaluation.test_in != '1'){
                valueEvaluationIn = true;
            }
            let factorsIQSize = this.state.factorsIq.length - 1;
            if(this.countFactors == factorsIQSize && valueEvaluationIn){
                this.saveStatusEvaluationMysql('4');
            }else{
                toast.info('Prueba finalizada correctamente');
                this.props.navigate('/list_evaluations/'+this.state.evaluation.candidatos_identificacion);
            }
        })
        .catch(err =>{
            toast.error(err);
            this.setState({loading: false, buttonDisabled: false});
        })
    }

    saveStatusEvaluationMysql(status){
        let data = {
            evaluation_id: this.props.params.evaluation_id,
            status: status
        }
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/evaluation_status', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                toast.info('Prueba finalizada correctamente');
                this.props.navigate('/list_evaluations/'+this.state.evaluation.candidatos_identificacion);
            }else{
                toast.error(data.text);
            }
        })
        .catch(err =>{
            toast.error(err);
            this.setState({loading: false, buttonDisabled: false});
        })
    }

    async validFactoresIQ(){
        const candidateResultsDoc = firebase.firestore()
            .collection('resultados_candidato')
            .doc(this.props.params.evaluation_id);

        // this.state.factorsIq.forEach(async (factor) => {
        //     let resultCadidate = await candidateResultsDoc.collection('resultados').doc(factor.id).get();
        //     if(resultCadidate.data()){
        //         this.countFactors++;
        //     }
        // })
        for (let index = 0; index < this.state.factorsIq.length; index++) {
            let factor = this.state.factorsIq[index];
            let resultCadidate = await candidateResultsDoc.collection('resultados').doc(factor.id).get();
            if(resultCadidate.data()){
                this.countFactors++;
            }
        }
        let factorsIQSize = this.state.factorsIq.length - 1;
        console.log('count',this.countFactors, factorsIQSize);
        
    }

    closeModal(){
        this.setState({isShowModal: false});
    }

    render(){
        // const { classes } = this.props;
        let minutesShow = this.state.minutes;
        let secondsShow = this.state.seconds;
        

        if(this.state.minutes < 10){
            minutesShow = '0'+this.state.minutes;
        }
        if(this.state.minutes <= 0){
            minutesShow = '00';
        }

        if(this.state.seconds < 10){
            secondsShow = '0'+this.state.seconds;
        }
        if(this.state.seconds == 60){
            secondsShow = '00';
        }
        
        let borderType = 'border-question-lg';
        if(window.screen.width <= 480){
            borderType = 'border-question-xs';
        }

        if(!this.state.loadingInit){
            return (
                <Preloader/>
            )
        }else{
            return(
                <div>
                    <div className="evaluation-header">
                        <Grid container item md={12} xs={12}>
                            <Grid item md={6} xs={6} style={{textAlign: 'right'}}>{this.state.titleTest}</Grid>
                            <Grid item md={6} xs={6}>
                            <div style={{width: '100%', textAlign: 'right'}}>
                                {/* <Image src={clock} 
                                    style={{width: 26, marginTop: -6, marginRight: 10}}
                                /> */}
                                <span className="text-clock">
                                    {minutesShow}:{secondsShow}
                                </span>
                            </div>
                            </Grid>
                        </Grid>
                        
                    </div>
                    <div className="body-evaluation-iq">
                        <Grid container item md={12} xs={12} >
                            {this.state.questions.length > 0 && this.state.questions.map((question, indexQ) => (
                                <Grid key={'Q'+indexQ} container item md={12} xs={12} 
                                    style={{border: 'solid 1px #A4A4A4', borderRadius: 15, marginBottom: 10}}
                                >
                                    <Grid item md={this.state.widthQuestion} className={borderType}>
                                        {question.imagen == '' ? 
                                        (<span>{question.pregunta}</span>)
                                        :
                                        (<Image src={const_values.IMAGE_CONNECT+question.imagen} 
                                            style={{}}
                                        />)
                                        }
                                        
                                    </Grid>
                                    {question.respuestas.map((answer, indexA) => (
                                        <Grid key={'A'+indexA} 
                                            md={Math.floor(10/question.respuestas.length) > 3 ? 3: Math.floor(10/question.respuestas.length)}
                                            item 
                                            style={{marginRight: 10, padding: 10}}
                                        >
                                            <Radio
                                                checked={this.state.answers_select[question.id].respuesta == answer.respuesta}
                                                value={answer.respuesta}
                                                name="radio-button-demo"
                                                inputProps={{'aria-label': answer.respuesta}}
                                                onChange={this.handlerOptions(question, answer)}
                                                sx={{
                                                    color: '#848484',
                                                    '&.Mui-checked': {
                                                        color: '#13c552',
                                                    },
                                                }}
                                            />
                                            {/* <CustomColorRadio
                                                checked={this.state.answers_select[question.id].respuesta == answer.respuesta}
                                                value={answer.respuesta}
                                                name="radio-button-demo"
                                                aria-label="A"
                                                onChange={this.handlerOptions(question, answer)}
                                            /> */}
                                            {answer.imagen == '' ? 
                                            (<span>{answer.respuesta}</span>)
                                            :
                                            (<Image src={const_values.IMAGE_CONNECT+answer.imagen} 
                                                style={{}}
                                            />)
                                            }
                                        </Grid>
                                    ))}
                                </Grid>
                            ))}
                        </Grid>
                        <div style={{textAlign: 'right', marginTop: 10}}>
                            <Button variant="contained" color="primary" onClick={this.save(false)}>
                                Finalizar test
                            </Button>
                        </div>
                    </div>
                    <Modal centered show={this.state.isShowModal} onHide={this.closeModal}>
                        <Modal.Body>
                            <div style={{margin: 10, color: '#585858'}}>
                                Tiene pregustas sin respoder desea finalizar el test de todas maneras?
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="contained" color="primary" onClick={this.closeModal}>
                            Regresar
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.save(true)}>
                            Finalizar test
                        </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className='div-logo-company'>
                        <Image src={const_values.IMAGE_COMPANY+this.state.evaluation.imagen} 
                            style={{height: 100}}
                        />
                    </div>
                </div>
            )
        }
    }
}

// EvaluationIQ.propTypes = {
//     classes: PropTypes.object.isRequired
// }

export default EvaluationIQ;
